<template>
  <div>
    <component :is="component" :popupParam="popupParam" />
  </div>
</template>

<script>
export default {
  name: 'appr-doc-todo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        approvalDocType: 'TODO', // 결재함 유형
      }),
    },
  },
  data() {
    return {
      editable: true,
      component: null, // 콤포넌트
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.component = () => import(`@/pages/sys/appr/apprList`);
    },
  }
};
</script>
